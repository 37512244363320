/* eslint-disable no-new-func */
import { useCallback, useEffect, useState } from "react";
import FormFooter from "../../shared/components/form-footer";
import FormSide from "../../shared/components/form-side/form-side";

import {
  StyledButton,
  StyledButtonContainer,
  StyledCenterImage,
  StyledContainer,
  StyledContentWrapper,
  StyledCover,
  StyledForm,
  StyledFormContainer,
  StyledImageLayer,
  StyledLoadingWrapper,
  StyledLogosContainer,
  StyledMainSection,
  StyledTermsOfServiceContainer,
} from "./check-form-styles";

import { TFooterEntity, TFormEntity, TFormsInfo } from "./types";
import FormInput from "../../shared/components/form-input";
import { getFieldValues, getValidation, inputsAttributes } from "./helpers";
import AddressInput from "../../shared/components/address-input";
import { createLead } from "../../shared/services/check-form/create-lead";
import { useParams, useNavigate } from "react-router-dom";
import { getEventBySlug } from "../../shared/services/events/getEventBySlug";
import { useSnackbar } from "notistack";
import isNilOrEmpty from "../../shared/utils/isNilOrEmpty";
import { Loading } from "../../shared/components";
import UrlImage from "../../shared/components/url-image/url-image";
import { createCheck } from "../../shared/services/check-form/create-check";
import DiagnosisLoading from "./loading";
import { Helmet } from "react-helmet";
import { isNil } from "ramda";
import AlertDialog from "../../shared/components/alert-dialog";
import { Checkbox } from "@mui/material";
import { Modal } from "../../shared/components/modal";
import { htmlToJsDom } from "../../shared/utils/html-to-js-dom";

const CheckForm = () => {
  const { event: eventSlug } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [formInfo, setFormInfo] = useState<TFormsInfo>({
    address: "",
    company: "",
    companyPhone: "",
    email: "",
    name: "",
    number: "",
    phone: "",
    zipCode: "",
    city: "",
    state: "",
    company_field: "",
    pdv_quantity: "",
    position_in_company: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isDiagnosisLoading, setIsDiagnosisLoading] = useState(false);
  const [isTermsOfServiceOpen, setIsTermsOfServiceOpen] = useState(false);
  const [isTermsOfServiceChecked, setIsTermsOfServiceChecked] = useState(false);

  const [formStyles, setFormStyles] = useState<TFormEntity>({});

  const [footerStyles, setFooterStyles] = useState<TFooterEntity>({});
  const [eventInfo, setEventInfo] = useState({ name: "", id: "" });

  const [position, setPosition] = useState(0);

  const [jsCode, setJsCode] = useState("");
  const [htmlCode, setHTMLCode] = useState("");

  const [codeRunning, setCodeRunning] = useState(false);
  const [isCommercial, setIsCommercial] = useState(false);
  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);
  const [hasPDVField, setHasPDVField] = useState(false);
  const [hasCompanyBusinessField, setHasCompanyBusinessField] = useState(false);
  const [hasPositionInCompanyField, setHasPositionInCompanyField] =
    useState(false);
  const [hasTermsOfService, setHasTermsOfService] = useState(false);

  const addressPosition = 8;
  const rightAfterUserInfoPosition = 3;

  const handleChangeForm = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const validation = getValidation(position);
      let formInput = getFieldValues(formInfo, position);

      if (formInput.phone) {
        const phone = formInput.phone.replaceAll(/[ ()_-]/g, "");
        formInput = { phone };
      }

      const isValid = await validation.isValid(formInput);

      if (!isValid) {
        return enqueueSnackbar("O valor informado não é válido!", {
          variant: "warning",
          autoHideDuration: 1500,
        });
      }

      const nextPosition = position + 1;
      const rightNextpdvQuantityPosition = 4;
      if (
        nextPosition === rightNextpdvQuantityPosition &&
        formInfo.pdv_quantity === "NONE"
      ) {
        setIsAlertDialogOpen(true);
        return;
      }

      return setPosition(nextPosition);
    },
    [position, formInfo, enqueueSnackbar]
  );

  const getEventSlug = useCallback(async () => {
    const getEventSlugResponse = await getEventBySlug({
      accessToken: "",
      slug: eventSlug || "default",
    });

    if (isNilOrEmpty(getEventSlugResponse)) {
      setIsLoading(false);
      return navigate("/");
    }

    if (!isNil(eventSlug) && getEventSlugResponse.slug !== eventSlug) {
      return navigate("/eventNotFound");
    }

    const {
      form,
      footer,
      id,
      status,
      name,
      request_information,
      request_pdv_quantity,
      request_company_field,
      request_position_in_company,
      request_terms_of_service,
      show_landing_page,
    } = getEventSlugResponse;

    if (status !== "ACTIVE") return navigate("/eventNotFound");

    if (!request_information) {
      setIsCommercial(true);
      setPosition(rightAfterUserInfoPosition);
      setFormInfo({
        address: "",
        company: "",
        companyPhone: "",
        email: "comercial@hublocal.com.br",
        name: "Comercial",
        number: "",
        phone: "(11) 11111-1111",
        zipCode: "",
        city: "",
        state: "",
        company_field: "",
        pdv_quantity: "",
        position_in_company: "",
      });
    }

    setHasCompanyBusinessField(request_company_field || false);
    setHasPDVField(request_pdv_quantity || false);
    setHasPositionInCompanyField(request_position_in_company || false);
    setHasTermsOfService(request_terms_of_service || false);

    const { sponsor } = footer;
    let currentSponsor = "";

    if (!isNilOrEmpty(sponsor)) {
      currentSponsor = sponsor[0].logo;
    }

    setEventInfo({ id, name });
    setFormStyles(form);
    setJsCode(form.js_code);
    setHTMLCode(form.html_code);
    setFooterStyles({ ...footer, sponsor: currentSponsor });
    setIsLoading(false);
  }, [eventSlug, navigate]);

  useEffect(() => {
    getEventSlug();
  }, [getEventSlug]);

  const handleDiagnosis = useCallback(async () => {
    if (hasTermsOfService && !isTermsOfServiceChecked) {
      return enqueueSnackbar("O termo de uso deve ser aceito para continuar.", {
        variant: "warning",
        autoHideDuration: 2000,
      });
    }

    setIsDiagnosisLoading(true);
    const {
      company_field: companyField,
      pdv_quantity: pdvQuantity,
      position_in_company: positionInCompany,
    } = formInfo;

    const lead = await createLead({
      address: formInfo.address,
      companyName: formInfo.company,
      companyPhone: formInfo.companyPhone,
      email: formInfo.email,
      eventId: eventInfo.id,
      name: formInfo.name,
      number: formInfo.number,
      phone: formInfo.phone,
      zipCode: formInfo.zipCode,
      state: formInfo.state,
      city: formInfo.city,
      company_field: companyField || undefined,
      pdv_quantity: pdvQuantity || undefined,
      position_in_company: positionInCompany || undefined,
    });

    if (isNilOrEmpty(lead)) {
      setIsDiagnosisLoading(false);
      return enqueueSnackbar("Algo deu errado no seu diagnóstico!", {
        variant: "warning",
        autoHideDuration: 1500,
      });
    }

    const check = await createCheck({
      address: formInfo.address,
      city: formInfo.city,
      companyName: formInfo.company,
      companyPhone: formInfo.companyPhone,
      leadId: lead.id,
      state: formInfo.state,
      number: formInfo.number,
      zipCode: formInfo.zipCode,
      isFetching: setIsDiagnosisLoading,
    });

    if (isNilOrEmpty(check)) {
      return enqueueSnackbar("Algo deu errado no seu diagnóstico!", {
        variant: "warning",
        autoHideDuration: 1500,
      });
    }
    navigate(`/diagnosis/${lead.id}`);
  }, [formInfo, navigate, enqueueSnackbar, eventInfo, isTermsOfServiceChecked]);

  const changeFormByFlag = useCallback(() => {
    const conditions: { [key: number]: boolean } = {
      3: !hasPDVField,
      6: !hasCompanyBusinessField,
      7: !hasPositionInCompanyField,
    };

    if (conditions[position]) {
      setPosition((currentPosition) => currentPosition + 1);
    }
  }, [
    position,
    hasPDVField,
    hasCompanyBusinessField,
    hasPositionInCompanyField,
  ]);

  const handleBackEvent = useCallback(() => {
    const conditions: { [key: number]: boolean } = {
      3: hasPDVField,
      6: hasCompanyBusinessField,
      7: hasPositionInCompanyField,
    };

    let counter = 1;
    do {
      let newPosition = position - counter;
      const isValidPosition =
        conditions[newPosition] || isNil(conditions[newPosition]);
      if (isValidPosition) {
        setPosition(newPosition);
        break;
      }
      counter += 1;
    } while (true);
  }, [
    hasPDVField,
    hasCompanyBusinessField,
    hasPositionInCompanyField,
    position,
  ]);

  const getInput = useCallback(() => {
    const nameLabel = `${formInfo.name}, qual é seu melhor e-mail? *`;
    changeFormByFlag();
    const inputs = inputsAttributes.map((attributes) => (
      <FormInput
        label={attributes.label || nameLabel}
        name={attributes.name}
        placeHolder={attributes.placeHolder}
        setValue={setFormInfo}
        value={formInfo}
        mask={attributes.mask}
        isSelect={!!attributes.isSelect}
        options={attributes.options}
      />
    ));
    return inputs[position];
  }, [position, formInfo, changeFormByFlag]);

  const Buttons = useCallback(() => {
    const positionToRemoveBackButton = hasPDVField ? 3 : 4;
    const backButton = (
      <StyledButton isGrey type="button" onClick={handleBackEvent}>
        Voltar
      </StyledButton>
    );
    return (
      <StyledButtonContainer>
        {!(
          position === 0 ||
          (position === positionToRemoveBackButton && isCommercial)
        ) && backButton}
        {position < addressPosition ? (
          <StyledButton>Próximo passo</StyledButton>
        ) : (
          <StyledButton type="button" onClick={handleDiagnosis}>
            Iniciar Diagnóstico
          </StyledButton>
        )}
      </StyledButtonContainer>
    );
  }, [position, handleDiagnosis, isCommercial, handleBackEvent, hasPDVField]);

  useEffect(() => {
    try {
      if (jsCode && !codeRunning) {
        new Function(jsCode)();
        setCodeRunning(true);
      }
      if (htmlCode && !codeRunning) {
        const htmlToJs = htmlToJsDom(htmlCode);
        new Function(htmlToJs.code!)();
        setCodeRunning(true);
      }
    } catch (err) {
      return;
    }
  }, [jsCode, codeRunning, htmlCode]);

  const handleReload = useCallback(() => window.location.reload(), []);

  return (
    <>
      <Helmet>
        <meta
          property="og:title"
          content={`${eventInfo.name || "Evento"} - Check Hublocal`}
        />
        <meta
          property="og:description"
          content="Verifique a preseça da sua empresa em várias plataformas de mapas."
        />
        <title>{`${eventInfo.name || "Evento"} - Check Hublocal`}</title>
      </Helmet>
      {isLoading ? (
        <StyledLoadingWrapper>
          <Loading large />
        </StyledLoadingWrapper>
      ) : (
        <StyledContainer>
          <StyledContentWrapper>
            <FormSide
              bannerBackground={formStyles.background_image}
              bannerImg={formStyles.left_second_layer}
              bannerText={formStyles.left_first_layer}
            />
            <StyledMainSection
              background={formStyles.background_image}
              textColor="#FFFFFF"
            >
              <StyledCover background={formStyles.background_image}>
                <StyledImageLayer src={formStyles.mobile_first_layer} />
                <StyledCenterImage src={formStyles.mobile_second_layer} />
                <StyledLogosContainer>
                  {formStyles.first_logo && (
                    <UrlImage url={formStyles.first_logo} />
                  )}
                  {formStyles.second_logo && (
                    <UrlImage url={formStyles.second_logo} />
                  )}
                </StyledLogosContainer>
                {isDiagnosisLoading ? (
                  <DiagnosisLoading />
                ) : (
                  <StyledForm
                    onSubmit={handleChangeForm}
                    isAddress={position === addressPosition}
                  >
                    <StyledFormContainer key={position}>
                      {position < addressPosition && getInput()}
                      {position === addressPosition && (
                        <>
                          <AddressInput
                            label="Por último, qual o endereço da sua Empresa?"
                            name="zipCode"
                            placeHolder="CEP"
                            setValue={setFormInfo}
                            value={formInfo}
                          />
                          {hasTermsOfService && (
                            <StyledTermsOfServiceContainer>
                              <Checkbox
                                style={{ color: "#0183ed" }}
                                checked={isTermsOfServiceChecked}
                                onClick={() =>
                                  setIsTermsOfServiceChecked((prev) => !prev)
                                }
                              />
                              <p>
                                Li e estou de acordo com os{" "}
                                <a
                                  href="#"
                                  onClick={() => setIsTermsOfServiceOpen(true)}
                                >
                                  Termos de uso de dados
                                </a>
                                .
                              </p>
                            </StyledTermsOfServiceContainer>
                          )}
                        </>
                      )}
                      <Buttons />
                    </StyledFormContainer>
                  </StyledForm>
                )}
              </StyledCover>
              {isTermsOfServiceOpen && (
                <Modal
                  header="Termo de uso de dados"
                  setIsModalOpen={setIsTermsOfServiceOpen}
                  onAccept={() => {
                    setIsTermsOfServiceChecked(true);
                    setIsTermsOfServiceOpen(false);
                  }}
                >
                  Ao informar os seus dados pessoais no nosso site para
                  pesquisar a sua empresa, a HUBLOCAL poderá utilizar tais
                  informações para realizar campanhas de marketing e divulgar os
                  seus serviços pelo período de até 5 anos. Em caso de alteração
                  das finalidades, da duração ou da forma de tratamento, a
                  HUBLOCAL informará a você, que deverá expressamente consentir
                  com o tratamento de seus dados conforme nova finalidade ou, se
                  desejar, revogá-lo. Caso você queira solicitar a exclusão de
                  suas informações do nosso banco de dados, que fica situado na
                  Virgínia do Norte (Leste dos EUA), deverá enviar um e-mail
                  para: marketing@hublocal.com.br.
                </Modal>
              )}
            </StyledMainSection>
          </StyledContentWrapper>

          <FormFooter
            backgroundColor={footerStyles.background_color}
            mainLogo={footerStyles.main_logo}
            textColor={footerStyles.text_color}
            poweredBy={footerStyles.sponsor}
          />
          {isAlertDialogOpen && (
            <AlertDialog isOpen={isAlertDialogOpen} onClose={handleReload} />
          )}
        </StyledContainer>
      )}
    </>
  );
};

export default CheckForm;
