import { useSnackbar } from "notistack";
import { isNil } from "ramda";
import { useCallback, useState } from "react";
import getAddressByZipCode from "../../services/check-form/getAddressByZipCode";
import isNilOrEmpty from "../../utils/isNilOrEmpty";
import {
  StyledInput,
  StyledInputMask,
  StyledInputWrapper,
  StyledLabel,
} from "../form-input/form-input-styles";
import { TFormInputProps } from "../form-input/types";
import {
  StyledContainer,
  StyledEnableAddressButton,
} from "./address-input-styles";

const AddressInput = ({
  label,
  name,
  placeHolder,
  setValue,
  value,
}: TFormInputProps) => {
  const [isDisabled, setIsDisabled] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleGetAddress = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue((prev) => ({ ...prev, [name]: e.target.value }));
      const cepSize = e.target.value.replaceAll("_", "");

      if (cepSize.length >= 9) {
        const addressResponse = await getAddressByZipCode({
          zipcode: e.target.value,
          feedbackMessage: enqueueSnackbar,
        });

        if (addressResponse?.erro === "true") return;

        if (isNilOrEmpty(addressResponse)) {
          setIsDisabled(false);
          return enqueueSnackbar("CEP não é válido", { variant: "warning" });
        }

        const address = `${addressResponse?.logradouro}, ${addressResponse?.bairro}, ${addressResponse?.localidade}, ${addressResponse?.uf}`;
        const state = String(addressResponse?.uf);
        const city = String(addressResponse?.localidade);
        setValue((prev) => ({ ...prev, address, state, city }));
      }
    },
    [enqueueSnackbar, setValue, name]
  );

  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <>
      <StyledLabel textColor="#FFFFFF">{label}</StyledLabel>
      <StyledContainer>
        <StyledInputWrapper>
          <StyledInputMask
            value={value.zipCode}
            mask="99999-999"
            name={name}
            onChange={handleGetAddress}
            required
            placeholder={placeHolder}
            type="tel"
          />
        </StyledInputWrapper>
        <StyledEnableAddressButton
          onClick={() =>
            openInNewTab(
              "https://buscacepinter.correios.com.br/app/endereco/index.php"
            )
          }
          type="button"
        >
          Não sei meu CEP
        </StyledEnableAddressButton>
      </StyledContainer>
      <StyledContainer>
        <StyledInput
          value={value.address}
          variant="standard"
          name="address"
          onChange={(e) =>
            setValue((prev) => ({ ...prev, address: e.target.value }))
          }
          required
          placeholder="Endereço"
          isbig={1}
          disabled={isDisabled}
        />
        <StyledInput
          value={value.number}
          variant="standard"
          name="number"
          onChange={(e) => {
              const isJustNumbers = e.target.value.match(/\D/);
              const isMaxLength = e.target.value.length > 9;

              if(isNil(isJustNumbers) && !isMaxLength){
                setValue((prev) => ({ ...prev, number: e.target.value }));
              }
            }
          }
          required
          placeholder="N°"
          type="tel"
          issmall={1}
        />
      </StyledContainer>
    </>
  );
};

export default AddressInput;
