import { TDiagnosisEntity } from "../services/diagnosis/types";

const platformsWithPhoneUnavailable = ["Instagram", "Facebook"];

const getPercentage = (value: number, total: number) =>
  parseFloat(Math.round((value * 100) / total).toFixed(2));

export default function getDiagnosisStatusPercentage(
  diagnosisLists: TDiagnosisEntity[]
) {
  const { correct, incorrect, nonexistent, total } = diagnosisLists.reduce(
    (counter, diagnosis) => {
      const { address_status, phone_status, name_status, platform } = diagnosis;

      const platformDoesNotReturnsPhone =
        !platformsWithPhoneUnavailable.includes(platform);

      if (platformDoesNotReturnsPhone) counter[phone_status] += 1;
      counter[address_status] += 1;
      counter[name_status] += 1;
      counter.total += platformDoesNotReturnsPhone ? 3 : 2;

      return counter;
    },
    { correct: 0, incorrect: 0, nonexistent: 0, total: 0 }
  );

  return {
    correct: getPercentage(correct, total),
    incorrect: getPercentage(incorrect, total),
    nonexistent: getPercentage(nonexistent, total),
  };
}
